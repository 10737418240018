<template>
  <div class="mt-4" v-if ="ratingResults.length">
    <h2>Рейтинг участников</h2>
    <ul class="list-group">
      <li v-for="(result, index) in ratingResults" :key="index" class="list-group-item">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <img v-if="result && result.photo_url" :src="result.photo_url" :alt="result.first_name" class="rounded-circle" style="width: 30px; height: 30px; margin-right: 10px;">

            <div class="d-flex justify-content-between align-items-center flex-grow-1">
              {{ result.first_name }} {{ result.last_name }} <span class="badge bg-primary ms-2">{{ result.score }}%</span>

              <button class="btn btn-light ms-2" @click="showTestResults(result.results_hash); copyTestResultsLink(result.results_hash);" title="Показать результаты теста и скопировать ссылку на результаты.">
                <i class="bi bi-share ml-2"></i>
              </button>
              <button class="btn btn-light ms-2 text-danger" v-if="user.id == result.user_id" @click="deleteResults(result.id);" title="Удалить результаты">
                <i class="bi bi-trash ml-2"></i>
              </button>

            </div>
          </div>
          <div class="text-secondary" v-if="!isMobile">
            {{ result.created_at }}
          </div>

        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { API_URL } from '@/config.js';
import { useToast } from "vue-toastification";
import { mapGetters, useStore } from 'vuex';
import { computed } from 'vue';
import axiosConfig from '@/lib/axiosConfig';


export default {
  data() {
    return {
      ratingResults: [],
    };
  },
  computed: {
    ...mapGetters('app', ['isMobile', 'configData']), 
    ...mapGetters("article", ["getArticleItem"]),
    articleItem: {
      get() {
        return this.getArticleItem || {};
      },
    }    
  },  
  setup(){
    const store = useStore();

    const user = computed(() => store.getters['user/getUser']);
    return {
      user
    }

  },
  methods: {
    fetchRatingResults() {

      axiosConfig
        .get(API_URL, {
          params: {
            action: 'get_rating_results',
          },
        })
        .then(response => {
          this.ratingResults = response.data;
        })

        .catch(error => {
          console.error('Error fetching rating results:', error);

          throw error;
        });

    },
    deleteResults(result_id){
      if(confirm("Подтверждаете удаление?")){
        this.$axios.post(API_URL, {
          params: {
            action: 'delete_test_results',
            result_id: result_id,
            // Другие параметры запроса
          }
        })
          .then(response => {          
            console.log(response);
            this.fetchRatingResults();
          })
          .catch(error => {
            console.log(error);
            // Handle save error
          });
      }
    },
    showTestResults(resultsHash) {
      this.$axios
        .get(API_URL, {
          params: {
            action: 'get_test_results',
            resultsHash: resultsHash,
          },
        })
        .then((response) => {
          const testResults = response.data;

          // Notify the TestComponent about the test results
          this.$emit('test-results-updated', testResults);

          // Display the test results in a modal/popup or any other desired way
          //alert(JSON.stringify(testResults)); // Example: Show test results in an alert
        })
        .catch((error) => {
          console.error('Error fetching test results:', error);
        });
    },

    copyTestResultsLink(resultsHash) {
      //const currentURL = new URL(window.location.href);
      //currentURL.searchParams.set('results_hash', resultsHash);
      
      let url = this.configData.web_app_link + 'article/'+this.articleItem.hash+'?results_hash='+resultsHash+'#test'
      navigator.clipboard.writeText(url)
        .then(() => {
          const toast = useToast();

          // or with options
          toast.success("Ссылка на результаты скопирована в буфер обмена.", {
            timeout: 2000
          })
        })
        .catch((error) => {
          console.error('Ошибка при копировании ссылки:', error);
        });
    }    
  },
  mounted() {
    this.fetchRatingResults();
  },
};
</script>

<style>
/* Custom styles for the component */
</style>
