<template>
  <div v-if="leaderboard && articleItem.group_alias && leaderboard.leaderboard && leaderboard.leaderboard.length">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Лидеры</h4>
        <h6 v-if="leaderboard.caption">В канале «{{leaderboard.caption}}»</h6>
        <div class="list-group">
          <a
            v-for="(leader) in leaderboard.leaderboard"
            :key="leader.userId"
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <div>
              <img v-if="leader && leader.photo_url" :src="leader.photo_url" :alt="leader.first_name" class="rounded-circle" style="width: 30px; height: 30px; margin-right: 10px;">
              <span>{{ leader.username }}</span>
            </div>
            <span class="badge bg-primary">{{ leader.score }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { API_URL } from '@/config.js';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      leaderboard: [],
    };
  },
  computed: {
    ...mapGetters('article', ['getArticleItem']),
    articleItem: {
      get() {
        return this.getArticleItem || {};
      },
      set(value) {
        this.updateArticleItem(value);
      },
    },
  },
  mounted() {
    this.fetchLeaderboard();
  },
  methods: {
    fetchLeaderboard() {
      this.$axios
        .get(API_URL, {
          params: {
            action: 'get_leaderboard',
            groupAlias: this.articleItem.group_alias,
          },
        })
        .then((response) => {
          this.leaderboard = response.data;

        })
        .catch((error) => {
          console.error('Error fetching leaderboard:', error);
        });
    },
  },
};
</script>

<style scoped>
/* Добавьте пользовательский стиль здесь */
</style>
