<template>
  <div class="alert alert-info">

    <p>Кликните на карточку материала, чтобы добавить его в <span v-if="collectionType == 0">плейлист</span><span v-if="collectionType == 1">курс</span>. Хотите добавить новые материалы?</p>

    <div><a href="/app/materials" class="btn btn-primary btn-sm">Добавить материал</a> <button class="btn btn-outline-primary btn-sm" @click="showAddList = true">Экспресс добавление</button> </div>


    <div class="form-group mt-2" v-if="showAddList">
      <label for="bot_username"><b>Укажите список youtube-ссылок</b></label>
      <div class="text text-ьгеув">Тут вы можете указать список youtube-видео, которые будут добавлены как уроки в этот курс. Название и обложка будут загружены автоматически. После этого вы сможете отредактировать название и добавить текстовый контент в материал.</div>
      <textarea type="text" id="bot_username" class="form-control" v-model="newMaterialsList"></textarea>

      <spinner-component :visible="videoListSavingProgress" class="mt-4"></spinner-component>

      <button class="btn btn-primary btn-sm mt-2" @click="saveVideoList()" :disabled="videoListSavingProgress || !newMaterialsList">Добавить из списка</button> 
      <button class="btn btn-outline btn-sm mt-2 ms-2" @click="showAddList = false" :disabled="videoListSavingProgress">Отмена</button> 
    </div>


  </div>


  <div class="row mt-3 materials-selector">
    <div class="col-6">
      <div class="list-group materials">
        <h3 class="mb-4">Мои материалы</h3>

        <div class="d-flex align-items-center mb-1">
          <input type="text" class="form-control" v-model="filter.query" placeholder="Поиск по названию" @change="applyFilterQuery">
        </div>

        <div class="list-scroll">
          <div
            class="list-group-item list-group-item-action d-flex align-items-start"
            v-for="(material, index) in materials"
            :key="index"
            @click="addMaterial(material)"
          >
              <div class="d-flex w-100">
                <div class="d-flex align-items-start w-100">
                  <div class="me-2"><img :src="material.smallpicture" alt="" class="thumb" /></div>

                  <div class="d-flex align-items-start w-100 justify-content-between">
                    <div>{{ material.caption }}</div>
                    <!-- <a :href="material.link" class="btn btn-light"><i class="text-dander bi bi-pencil"></i></a> -->
                  </div>
                </div>
                
              </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6">
      <div class="list-group">
        <h3 class="mb-4" v-if="collectionType == 0">Материалы в плейлисте</h3>
        <h3 class="mb-4" v-if="collectionType == 1">Материалы в курсе</h3>

        

        <draggable
          :list="selectedMaterials"
          class="list-group"
          item-key="priority"
          @end="onDragEnd"
        >
          <div
            class="list-group-item list-group-item-action"
            v-for="(material, index) in selectedMaterials"
            :key="material.id" 
          >
            <div class="d-flex w-100 justify-content-between">
              <div class="d-flex align-items-start">
                <div class="me-2"><img :src="material.smallpicture" alt="" class="thumb" /></div>
                <div>{{ material.caption }}</div>
              </div>
              <button @click="removeMaterial(material.id)" class="btn btn-light"><i class="text-danger bi bi-trash"></i></button>
            </div>
            <div class="mt-2" v-if="collectionType == 1">

              <select v-model="localOpenCondition[index]" class="form-select"  @change="saveCollectionMaterials(material.id, index)">
                <option value="0">Открыто</option>
                <option value="1" v-if="index">Оценка за предыдущий тест</option>
                <option value="2">Открыть по дате</option>
                <option value="3">Открыть по оплате</option>
              </select>
              
              <select
                  v-if="localOpenCondition[index] === '1'"
                  v-model="localOpenByTestScore[index]"
                  class="form-select mt-2"
                  @change="saveCollectionMaterials(material.id, index)"
                >
                <option value="0">0</option>
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
                <option value="100">100</option>
              </select>
              <input
                v-if="localOpenCondition[index] === '2'"
                type="date"
                v-model="localOpenByDate[index]"
                class="form-control mt-2"
                @input="saveCollectionMaterials(material.id, index)"
              >

              <!--  -->
              <div class="form-group mt-2" v-if="localOpenCondition[index] !== '0'">
                  <label for="description">Стоимость доступа, ₽ </label>
                  <input
                    type="text"
                    v-model="localOpenByPayment[index]"
                    class="form-control mt-2"
                    @input="saveCollectionMaterials(material.id, index)"
                  >
                  <div class="mt-1 text text-danger text-sm" v-if="localOpenByPayment[index] !=='0' && collectionItem && collectionItem.tariff.tariff == 'Free'">⚠️ Настройте прием платежей в этом курсе на вкладке Telegram</div>
              </div>  
              

            </div>
            <a class="btn btn-outline-primary w-100 mt-2" target="_blank" :href="material.link">Открыть</a>
          </div>
        </draggable>
      </div>
    </div>
  </div>
</template>


<script>
import { useToast } from "vue-toastification";
import { reactive, toRefs, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { mapGetters } from 'vuex';
import { VueDraggableNext } from 'vue-draggable-next';
import SpinnerComponent from '@/components/SpinnerComponent.vue';

export default {
  name: 'MaterialSelectorComponent',
  components: {
    draggable: VueDraggableNext,
    SpinnerComponent
  },
  props: {
    collectionId: {
      type: Number,
      default: 0,
    },
    collectionType: {
      type: Number,
      default: 0,      
    }
  },
  data() {
    return {
      showAddList: false,
      newMaterialsList: '',
      videoListSavingProgress: false,
      filter: {query: ''},
      //collections: [],
    };
  },

  methods:{

    fetchCollectionMaterials() {
      return this.$store.dispatch('material/fetchCollectionMaterials', this.collectionId)
        .then(() => {
          console.log('Материалы коллекции успешно загружены');
        })
        .catch(error => {
          console.error('Ошибка при загрузке материалов коллекции:', error);
        });
    },


    applyFilterQuery(){
      this.fetchMaterials({query: this.filter.query});
      //this.loadCollections();
    },

    saveVideoList(){
      this.videoListSavingProgress = true;
      this.saveCollectionMaterialsFromList(this.newMaterialsList)
        .then(() => {
          console.log('fetch materials');
          this.videoListSavingProgress = false;
          this.newMaterialsList = '';
          return this.fetchCollectionMaterials();
        })
        .catch(error => {
          console.error('Ошибка при сохранении списка материалов:', error);
          // Обработка ошибки при сохранении списка материалов
        });
    },

    fetchMaterials(payload) {
      console.log(payload);

      //this.setMaterials([]);
      this.$store.dispatch('material/fetchMaterials', payload)
        .then((data) => {
          this.materials = data.materials;
          // var materials = data.materials;
          // materials.forEach((material) => {
          //   const channelIndex = this.channels.findIndex(ch => ch.id === material.channel_id);
          //   material.channelIndex = channelIndex;
          // });
  
          //console.log(materials);
          //this.setMaterials(materials);
          //this.$forceUpdate();
          // Обработка успешной загрузки списка подборок
          //this.materials = data.list;
        })
        .catch((error) => {
          console.log(error);
          // Обработка ошибок при загрузке списка подборок
        });
    },

  },
  computed: {
    ...mapGetters("collection", ["getCollectionItem"]),
    collectionItem: {
      get() {
        return this.getCollectionItem || {};
      }
    },       
  },

  setup(props) {
    const store = useStore();

    const state = reactive({
      localOpenCondition: [],
      localOpenByTestScore: [],
      localOpenByPayment: [],      
      localOpenByDate: [],
    });

    const materials = computed(() => store.state.material.materials);
    //const selectedMaterials = computed(() => store.state.material.collectionMaterials);
    const selectedMaterials = computed(() => {
      return JSON.parse(JSON.stringify(store.state.material.collectionMaterials));
    });


    const initConditions = () => {
      state.localOpenCondition = new Array(selectedMaterials.value.length).fill('0');
      state.localOpenByTestScore = new Array(selectedMaterials.value.length).fill('0');
      state.localOpenByPayment = new Array(selectedMaterials.value.length).fill('0');
      //state.localOpenByDate = selectedMaterials.value.map(material => material.open_by_date || ''); // Может быть пустой строкой, если нет значения

    };



   const addMaterial = (material) => {
      material.open_condition = '0';

      // Добавляем новый материал в массив selectedMaterials
      store.dispatch('material/addMaterialToCollection', material);

      // Инициализируем localOpenCondition для нового материала
      state.localOpenCondition.push('0');

      const newMaterialsCount = selectedMaterials.value.length;
      store.commit('collection/updateMaterialsCountInCollection', { collectionId: props.collectionId, materialsCount: newMaterialsCount });

      // Сохраняем материалы подборки в хранилище
      saveCollectionMaterials();
      const toast = useToast();
      toast.success("Материал добавлен в плейлист", {
        timeout: 2000,
      });

    };

    const removeMaterial = (materialId) => {
      const newMaterialsCount = selectedMaterials.value.length - 1;
  
      store.commit('collection/updateMaterialsCountInCollection', { collectionId: props.collectionId, materialsCount: newMaterialsCount });

      store.dispatch('material/removeMaterialFromCollection', materialId);

      saveCollectionMaterials();
      const toast = useToast();
      toast.success("Материал удален из плейлиста", {
        timeout: 2000,
      });      
    };

    const onDragEnd = (event) => {
      const { oldIndex, newIndex } = event;

      // Перемещаем материал в массиве
      store.dispatch('material/moveMaterialInCollection', {
        oldIndex,
        newIndex,
        collectionId: props.collectionId,
      });


       [state.localOpenCondition[oldIndex], state.localOpenCondition[newIndex]] = [state.localOpenCondition[newIndex], state.localOpenCondition[oldIndex]];
      [state.localOpenByTestScore[oldIndex], state.localOpenByTestScore[newIndex]] = [state.localOpenByTestScore[newIndex], state.localOpenByTestScore[oldIndex]];
      [state.localOpenByPayment[oldIndex], state.localOpenByPayment[newIndex]] = [state.localOpenByPayment[newIndex], state.localOpenByPayment[oldIndex]];
      [state.localOpenByDate[oldIndex], state.localOpenByDate[newIndex]] = [state.localOpenByDate[newIndex], state.localOpenByDate[oldIndex]];


      // Обновляем поле 'priority' каждого материала
      selectedMaterials.value.forEach((material, index) => {
        store.commit('material/updateMaterialPriority', {
          id: material.id,
          priority: index,
        });
      });

      saveCollectionMaterials(); 
    };


    const saveCollectionMaterialsFromList = async (materialsList) => {
      try {

        await store.dispatch('material/updateCollectionMaterialsFromList', {
          collectionId: props.collectionId,
          materialsList: materialsList
        });

        const toast = useToast();
        toast.success("Список материалов обновлен", {
          timeout: 2000,
        });
      } catch (error) {
        console.error('Ошибка при сохранении списка материалов:', error);
        // Обработка ошибки при сохранении списка материалов
      }
    };



    const saveCollectionMaterials = (materialId, index) => {
      store.commit('material/updateOpenCondition', { 
        id: materialId, 
        openCondition: state.localOpenCondition[index], 
        openByTestScore: state.localOpenByTestScore[index],
        openByDate: state.localOpenByDate[index],
        openByPayment: state.localOpenByPayment[index]
      });

      store.dispatch('material/updateCollectionMaterials', {
        collectionId: props.collectionId,
      });

      const toast = useToast();
      toast.success("Список материалов обновлен", {
        timeout: 2000,
      });    
    };


    onMounted(async () => {
      initConditions();
      await store.dispatch('material/fetchMaterials');
      await store.dispatch('material/fetchCollectionMaterials', props.collectionId);

      

      //store.dispatch('material/clearCollectionMaterials');
    });

    watch(
      () => store.state.material.collectionMaterials,
      (newCollectionMaterials) => {
        // Update localOpenCondition and localOpenByTestScore whenever collectionMaterials change
        state.localOpenCondition = newCollectionMaterials.map(material => material.open_condition || '0');
        state.localOpenByTestScore = newCollectionMaterials.map(material => material.open_by_test_score || '0');
        state.localOpenByPayment = newCollectionMaterials.map(material => material.open_by_payment || '0');
        state.localOpenByDate = newCollectionMaterials.map(material => material.open_by_date || '');
        console.log('localOpenCondition:', state.localOpenCondition);
        console.log('localOpenByTestScore:', state.localOpenByTestScore);
        console.log('localOpenByPayment:', state.localOpenByPayment);
        console.log('localOpenByDate:', state.localOpenByDate);
      }
    );

    return {
      ...toRefs(state),
      materials,
      selectedMaterials,
      addMaterial,
      removeMaterial,
      onDragEnd,
      saveCollectionMaterials,
      saveCollectionMaterialsFromList
    };
  },
};
</script>

<style scoped>
.list-group-item {
  cursor: pointer;
}

.list-group-item:hover {
  background-color: #eee;
}

.materials-selector .thumb{
  border-radius: 6px;
  width: 40px;
  min-width: 40px;
  height: auto;
}

.list-scroll{
  height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
