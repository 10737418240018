<template>
  <a name="files"></a>
  <div class="mt-4">
    <h2>Плейлисты и курсы</h2>


    <spinner-component :visible="listLoading" class="mt-4"></spinner-component>

    <div v-if="hasEditAccess">
      <div class="form-group mb-2">
        <label for="channel" class="strong">Выберите канал:</label>
        <select class="form-control" v-model="articleItem.channel_id" @change="selectChannelForMaterial">
          <option value="">Выберите канал</option>
          <option v-for="channel in channels" :key="channel.id" :value="channel.id">{{ channel.caption }}</option>
        </select>
      </div>

      
      <div class="form-group mb-2" v-if="articleItem.channel_id">
        <label for="collection" class="strong">Выберите плейлист или курс:</label>
        <select class="form-control" v-model="selectedCollection" @change="addCollectionToMaterial">
          <option value="">Выберите плейлист</option>
          <option v-for="collection in collections" :key="collection.id" :value="collection.id">{{ collection.caption }}</option>
        </select>
      </div>

      <div class="collections mt-3" v-if="articleItem.collections && articleItem.collections.length">
        <div class="list-group">
          <div
            v-for="collection in articleItem.collections"
            :key="collection.collection_id"
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            {{ collection.caption }}
            <div @click="removeMaterialFromCollection(articleItem.id, collection.collection_id)" class="btn btn-danger btn-sm">
              Удалить
            </div>
          </div>
        </div>
      </div>

    </div>

    <div v-if="!hasEditAccess">
      <div class="collections mt-3" v-if="articleItem.collections && articleItem.collections.length">
        <div class="list-group">

          <a :href="collection.link"
            v-for="collection in articleItem.collections"
            :key="collection.collection_id"
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            {{ collection.caption }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { API_URL } from '@/config.js';
import { computed} from 'vue';
import { mapActions, mapGetters, mapMutations, useStore} from 'vuex';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import { formatFileSize } from '@/lib/filters';
import { useToast } from "vue-toastification";


export default {
  props: {
    materialId: null
  },  
  components: {
    SpinnerComponent,
  },  
  data() {
    return {
      listLoading: false,
      files: [],
      selectedCollection: null,

    };
  },
  setup(){
    const store = useStore();

    const hasEditAccess = computed(() => store.getters['user/hasEditAccess']);

    return {
      formatFileSize,
      hasEditAccess
    };
  },
  computed: {
    ...mapGetters('app', ['isMobile']), 
    ...mapGetters("article", ["getArticleItem"]),
    ...mapGetters('collection', ['getCollectionItem', 'getCollections']),
    ...mapGetters('channel', ['getChannelItem', 'getChannels']),
    articleItem: {
      get() {
        return this.getArticleItem || {};
      },
      set(value) {
        this.updateArticleItem(value);
      },
    },
    collections: {
      get() {
        return this.getCollections || [];
      }
    },
    channels: {
      get() {
        return this.getChannels || [];
      },
      set(value) {
        this.$store.commit('channel/setChannels', value);
      },
    }
  },
  methods: {
    ...mapMutations('collection', ['setCollectionItem', 'setCollections']),
    ...mapActions('collection', [ 'fetchCollections']),    
    ...mapActions('material', [ 'removeCollectionFromMaterial']),    
    ...mapMutations('material', ['setMaterials', 'setMaterialItem']),
    ...mapMutations('channel', ['setChannelItem', 'setChannels']),
    ...mapActions('channel', ['fetchChannel', 'saveChannel', 'deleteChannel', 'editMaterial', 'deleteMaterial', 'getMaterialsForChannel', 'fetchChannels', 'saveChannelCoverImage']),    

    loadCollections(payload) {

      //console.log(this.selectedChannel);


      if (this.articleItem.channel_id) {
        //payload = this.selectedChannel;
        payload = { channelId: this.articleItem.channel_id }
      }

      if (payload){
        this.$store.dispatch('collection/fetchCollections', payload)
          .then((data) => {
            //console.log(data.collections);
            //this.collections = data.collections;
            this.setCollections(data.collections);
            this.setMaterialItem(this.articleItem);
          })
          .catch(error => {
            console.log(error);
          });
      }
    
    },

    async saveMaterialSettings() {
      console.log(this.articleItem);

      const updatedMaterial = {
        id: this.articleItem.id,
        caption: this.articleItem.caption,        
        description: this.articleItem.description,        
        content: this.articleItem.content,        
        channelId: this.articleItem.channel_id,
        collections: this.articleItem.collections
      };

            
      try {
          const data = await this.$store.dispatch('material/saveMaterialSettings', updatedMaterial);
          console.log(data);

          if (!updatedMaterial.id){
            this.cancelMaterial();
            this.fetchMaterials();
            const toast = useToast();
            toast.success('Новый материал добавлен', {
              timeout: 2000,
            });
          } else {
            const toast = useToast();
            toast.success('Настройки материала сохранены', {
              timeout: 2000,
            });
          }

      } catch (error) {
        console.log(error);
        // Обработка ошибок при сохранении
      }

    },

    selectChannelForMaterial(){
      //console.log(this.selectedChannel);
      
      //this.articleItem.channel_id = this.articleItem.channel_id;
      //this.setMaterialItem(this.articleItem);


      this.loadCollections();
    },

    removeMaterialFromCollection(materialId, collectionId) {
      console.log(collectionId);
      const index = this.articleItem.collections.findIndex(
        (collection) => collection.collection_id === collectionId
      );
      console.log(index);
      if (index !== -1) {
        this.articleItem.collections.splice(index, 1);
      }
      this.saveMaterialSettings();
    },
    addCollectionToMaterial() {
      if (this.selectedCollection) {
        //console.log(this.collections);
        const collectionToAdd = this.collections.find(collection => collection.id === this.selectedCollection);
        if (collectionToAdd) {
          //console.log(this.articleItem);
          if (typeof this.articleItem.collections == 'undefined') this.articleItem.collections = [];
          const existingCollection = this.articleItem.collections.find(collection => collection.collection_id === this.selectedCollection);
          if (!existingCollection) {
            this.articleItem.collections.push({
              collection_id: collectionToAdd.id,
              caption: collectionToAdd.caption,

            });
          }
        }
        this.setMaterialItem(this.articleItem);
        this.saveMaterialSettings();
      }
      this.selectedCollection = null;
    },
  },
  mounted() {
    //this.getFiles();
    this.fetchChannels();
    this.loadCollections({});
  }
};
</script>

<style>
/* Стили для компонента */
</style>
