<template>
  <a name="timecodes"></a>
  <div class="mt-4">
    <h2>Файлы</h2>
    <div class="alert alert-info" v-if="files.length">Файлы хранятся в Telegram. Для загрузки файла перейдите по ссылке и нажмите кнопку «Начать». Чат-бот пришлет вам файл.</div>
    <spinner-component :visible="listLoading" class="mt-4"></spinner-component>

    <ul class="list-group mb-3">
      <li v-for="file, file_index in files" :key="file.id" class="list-group-item d-flex justify-content-between align-items-center">
        <div>
          <a :href="file.tg_file_link" target="_blank">{{ file.name }}</a>
          <!-- Здесь можно добавить размер файла, если это информация доступна в объекте file -->
          <span class="badge bg-secondary ms-2">{{ formatFileSize(file.size) }}</span>
        </div>
        <!-- Кнопка "Удалить" -->
        <button type="button" class="btn btn-danger" @click="confirmFileDelete(file, file_index)" v-if="hasEditAccess">
          Удалить
        </button>
      </li>
    </ul>

    <div class="form-group mb-2" v-if="hasEditAccess">
      <label for="materialFile">Добавить файл</label>
      <div><input type="file" id="materialFile" @change="handleFileUpload" accept="*/*" multiple></div>

    </div>
  </div>
</template>


<script>
import { API_URL } from '@/config.js';
import { computed} from 'vue';
import { mapActions, mapGetters, useStore} from 'vuex';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import { formatFileSize } from '@/lib/filters';

export default {
  props: {
    materialId: null
  },  
  components: {
    SpinnerComponent,
  },  
  data() {
    return {
      listLoading: false,
      files: []
    };
  },
  setup(){
    const store = useStore();

    const hasEditAccess = computed(() => store.getters['user/hasEditAccess']);
    return {
      formatFileSize,
      hasEditAccess
    };
  },
  computed: {

    ...mapGetters('app', ['isMobile']), 
    ...mapGetters("article", ["getArticleItem"]),
    articleItem: {
      get() {
        return this.getArticleItem || {};
      },
      set(value) {
        this.updateArticleItem(value);
      },
    },
  },
  methods: {
    ...mapActions('material', ['saveMaterialFile', 'deleteMaterialFile']),    
    getFiles() {
      this.listLoading = true;
      this.$axios
        .get(API_URL, {
          params: {
            action: 'get_material_files',
            materialId: this.articleItem.id
          }
        })
        .then((response) => {
          this.files = response.data.files;
          this.listLoading = false;
        })
        .catch((error) => {
          this.listLoading = false;
          console.error('Error fetching timecodes:', error);
        });
    },

    confirmFileDelete(file, fileIndex) {
      if (window.confirm('Подтверждаете удаление?')) {
        this.handleDeleteMaterialFile(file, fileIndex);
      }
    },


    async handleDeleteMaterialFile(file, fileIndex){
      try {
          this.files.splice(fileIndex, 1);
          this.articleItem.files_cnt --;

          // Вызываем экшен для сохранения обложки на сервере
          await this.deleteMaterialFile({
            materialId: this.articleItem.id,
            fileHash: file.file_hash
          });



        } catch (error) {
          console.error('Error deleting file:', error);
        }
    },
    async handleFileUpload(event) {

      const files = event.target.files;
      var file = {};
      var new_file_index = {};
      for(let i = 0; i < files.length; i++) {
        // Здесь вы можете обработать каждый файл
        file = files[i];
        

        this.files.push(file);
        new_file_index[i] = this.files.length - 1;
        console.log(file);


        //const file = event.target.files[0];
        //console.log(file);
        if (!file) return; // Если файл не выбран, ничего не делаем

        // Подготавливаем файл для загрузки с помощью FormData
        const formData = new FormData();
        formData.append('materialFile', file);

        this.fileUploading = true;

        try {
          // Вызываем экшен для сохранения обложки на сервере
          const response = await this.saveMaterialFile({
            materialId: this.articleItem.id,
            fileForm: formData
          });

          this.articleItem.files_cnt ++;

          console.log(response);
          this.files[new_file_index[i]] = response.file;
          this.fileUploading = false;
        } catch (error) {
          this.fileUploading = false;
          
          console.error('Error uploading file:', error);
        }
      }
    },


  },
  mounted() {
    this.getFiles();
  }
};
</script>

<style>
/* Стили для компонента */
</style>
