<template>
  <div>
    <div v-if="articleItem.chatbot_name == 'vid2text'" class="mb-2">
      <button @click="copyTextFromDiv('extra_data_container_' + typeId)" class="btn btn-outline-success me-2">
        Копировать текст
      </button>
    </div>

    <div :id="'extra_data_container_' + typeId">
      <div v-for="(data, index) in articleItem.extra_data_types[typeId].extra_data_list" :key="index" class="card mb-3">
        <div class="card-body">
          {{ data }}
        </div>
      </div>
    </div>
    <!-- Add more fields to display additional data as needed -->
  </div>
</template>

<script>

import { useToast } from "vue-toastification";
import useClipboard from 'vue-clipboard3'

export default {
  props: {
    articleItem: {
      type: Object,
      required: true
    },
    typeId: {
      type: Number,
      required: true
    }
  },

  setup(){
    const { toClipboard } = useClipboard()
    const copy = async (text, clean_html) => {
      try {
        if (clean_html){
          text = this.stripHTML(text);
        }

        await toClipboard(text)
        //console.log('Copied to clipboard')
        const toast = useToast();

        // or with options
        toast.success("Скопировано в буфер обмена", {
          timeout: 2000
        });
      } catch (e) {
        console.error(e)
      }
    }
    return {
      copy
    }

  },
  methods: {
    stripHTML(html) {
      const doc = new DOMParser().parseFromString(html, 'text/html');
      doc.querySelectorAll('li').forEach(el => {
        el.textContent = '– ' + el.textContent;
        el.insertAdjacentHTML('afterend', '\n');
      });
      doc.querySelectorAll('h1, h2, h3, h4, h5, p, div').forEach(el => {
        el.insertAdjacentHTML('afterend', '\n\n');
      });
      doc.querySelectorAll('ul').forEach(el => {
        el.insertAdjacentHTML('afterend', '\n\n');
      });

      let text = doc.body.textContent || "";
      text = text.replace(/\n{3,}/g, '\n\n');

      return text;
    },

    copyTextFromDiv(elementId) {
      const element = document.getElementById(elementId);
      if (element) {
        const textToCopy = this.stripHTML(element.innerHTML);
        this.copy(textToCopy);
      }
    }
  },

  mounted() {
    console.log(this.typeId);
  }
}
</script>
