<template>
  <div class="sidebar" v-if="appMounted">
    <!-- <ProjectCardComponent /> -->
    <CollectionArticlesComponent v-if="articleItem.id && articleItem.collection_id"/>

    <LeaderboardComponent  v-if="articleItem.id && !articleItem.collection_id" class="mb-2"/>

    <OtherArticlesComponent v-if="articleItem.id && !articleItem.collection_id && !articleItem.disable_other_articles"/>


  </div>
</template>

<script>
import LeaderboardComponent from './LeaderboardComponent.vue';
import OtherArticlesComponent from './OtherArticlesComponent.vue';
import CollectionArticlesComponent from './CollectionArticlesComponent.vue';
import { mapGetters } from "vuex";
//import ProjectCardComponent from './ProjectCardComponent.vue';

export default {
  components: {
    LeaderboardComponent,
    OtherArticlesComponent,
    CollectionArticlesComponent
    //ProjectCardComponent
  },
  computed: {
    ...mapGetters('app', ['isMobile']), 
    ...mapGetters("article", ["getArticleItem"]),
    articleItem: {
      get() {
        return this.getArticleItem || {};
      },
      set(value) {
        this.updateArticleItem(value);
      },
    },
  }, 
  data() {
    return {
      appMounted: false
    };
  },
  methods:{
    setAppMounted(){
      this.appMounted = true;
    }
  },
  mounted(){
    //this.appMounted = true;
  },
  created() {
    //const hash = this.$route.params.id;
    //console.log(hash);
    //this.updateHash(hash); // Dispatch the updateHash action
    //if ()
    this.timer = setTimeout(this.setAppMounted, 1000);

  },
};
</script>

<style scoped>
.sidebar {

}
</style>
