<template>
<div :class="displayFormat" class="other-articles " v-if="otherArticles.length">
  <div class="card border-0" v-if="displayFormat === 'vertical'">
    <div class="">
      
      <div v-if="displayFormat === 'vertical'">
        <h4 class="card-title">Другие материалы</h4>
        <ul class="list-group">
          <li v-for="item in otherArticles" :key="item.id" class="list-group-item">
            
            <div class="align-items-center">
              <div><a :href="item.link" class="text-decoration-none"><img :src="item.cover_image" v-if="item.cover_image" class="mb-2"></a></div>
              <div class="d-flex">
                <!-- <i class="text-primary bi bi-file-earmark doc-icon"></i> -->
                <a :href="item.link" class="text-decoration-none">{{ item.title }}</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  
  <div v-if="displayFormat === 'horizontal'" class="mt-3">

    <h2>Другие материалы</h2>  
    <div class="row align-items-stretch">
      <div v-for="item in otherArticles" :key="item.id" class="col-4">
        <div class="card h-100">
          <div class="card-body">
            <a :href="item.link" class="text-decoration-none"><img :src="item.cover_image" v-if="item.cover_image"></a>
            <div><a :href="item.link" class="text-decoration-none">{{ item.title }}</a></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import { API_URL } from '@/config.js';
import { mapGetters } from "vuex";

export default {
  props: {
    displayFormat: {
      type: String,
      default: 'vertical' // Default display format is vertical
    }
  },
  data() {
    return {
      otherArticles: []
    };
  },
  computed: {
    ...mapGetters('article', ['getArticleItem']),
    articleItem: {
      get() {
        return this.getArticleItem || {};
      },
      set(value) {
        this.updateArticleItem(value);
      },
    },
  },
  methods: {
    fetchOtherArticles() {
      //console.log('fetch othher articles');
      this.$axios
        .get(API_URL, {
          params: {
            action: 'get_other_articles',
            groupAlias: this.articleItem.group_alias
          }
        })
        .then((response) => {
          this.otherArticles = response.data.articles;
        })
        .catch((error) => {
          console.error('Error fetching other materials:', error);
        });
    }
  },
  mounted() {
    this.fetchOtherArticles();
  }
};
</script>

<style>
/* Custom styles for the component */
.card img{
  width: 100%;
  height: auto;
/*  max-width: 400px;*/
  border-radius: 6px;
}

.vertical .card img{
  /*width: 50px;
  height: auto;*/
}

.other-articles .list-group-item{
  border: 0;
  padding: 0 0 20px 0;

}

.doc-icon{
  font-size: 30px;
  margin-right: 8px;
}
</style>
