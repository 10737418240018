// channelModule.js
import axiosConfig from '@/lib/axiosConfig';
import { API_URL } from '@/config.js';

const channelModule = {
  namespaced: true,
  state: {
    channelItem: null,
    channels: null,
    materials: null
  },
  mutations: {
    setChannelItem(state, channel) {
      //console.log(channel);
      state.channelItem = channel;
    },
    setMaterials(state, materials) {
      state.materials = materials;
    },
    setChannels(state, channels) {
      //console.log(channels);
      state.channels = channels;
    },
    updateChannelItem(state, updatedChannel) {
      state.channelItem = { ...state.channelItem, ...updatedChannel };
    },
    updateMaterialsCountInChannel(state, payload) {
      const channel = state.channels.find(coll => coll.id === payload.channelId);
      if (channel) {
        channel.materials_count = payload.materialsCount;
      }
    },
    setChannelCoverImage(state, { channelId, coverImageUrl }) {
      const channelIndex = state.channels.findIndex(channel => channel.id === channelId);
      if (channelIndex !== -1) {
        state.channels[channelIndex].coverImageUrl = coverImageUrl;
      }
    },    
  },
  actions: {
    fetchChannel({ commit }, payload) {
      if (typeof payload.channelId == 'undefined') return {};
      var params = { action: 'get_channel', id: payload.channelId, material_type: payload.materialsType};


      return axiosConfig
        .get(API_URL, {
          params: params,
        })
        .then(response => {
          const channel = response.data.channel;
          commit('setChannelItem', channel);
          return response.data;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },
    fetchChannelMaterials({ commit },  { channelId, materialsType, categoryId }) {
      console.log(materialsType);
      return axiosConfig
        .get(API_URL, {
          params: {
            action: 'get_channel_materials',
            channelId: channelId,
            materialsType: materialsType,
            categoryId: categoryId
          },
        })
        .then(response => {
          console.log(response.data);
          const materials = response.data.channel_materials;
          commit('setMaterials', materials);
          return response.data;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },    
    fetchChannels({ commit }) {
      return axiosConfig
        .get(API_URL, {
          params: {
            action: 'get_channels',
          },
        })
        .then(response => {
          const channels = response.data.channels;
          console.log(channels);
          commit('setChannels', channels);
          return response.data;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },
    saveChannel({ commit }, channelItem) {
      return axiosConfig
        .post(API_URL, {
          params:{
            action: 'save_channel',
            channel: channelItem
          }
        })
        .then(response => {
          console.log(response);

          const channel = response.data.channel;
          commit('setChannelItem', channel);          
          return response.data;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },
    deleteChannel({ state, commit }) {
      const { channelItem } = state;
      if (!channelItem || !channelItem.id) {
        throw new Error('Invalid channel item');
      }

      console.log(state);
      return axiosConfig
        .post(API_URL, {
          params:{
            action: 'delete_channel',
            channelId: channelItem.id
          }
        })
        .then(response => {
          console.log(response);
          commit('setChannelItem', null); // Обновление channelItem в хранилище после удаления
          return response.data;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },

    saveChannelCoverImage({ commit }, { channelId, coverImageForm }) {
      coverImageForm.append('action', 'save_channel_cover_image');
      coverImageForm.append('channelId', channelId);
          
      return axiosConfig.post(API_URL, coverImageForm, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          // Если обложка подборки успешно сохранена на сервере, обновляем данные о коллекции в хранилище
          const channel = response.data.channel;
          commit('setChannelItem', channel);
          return response.data;
        })
        .catch(error => {
          console.error('Error uploading cover image:', error);
          throw error;
        });
    },

    saveChannelLogo({ commit }, { channelId, logoForm, type }) {
      logoForm.append('action', 'save_channel_logo');
      logoForm.append('channelId', channelId);
      logoForm.append('type', type);
          
      return axiosConfig.post(API_URL, logoForm, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          // Если обложка подборки успешно сохранена на сервере, обновляем данные о коллекции в хранилище
          const channel = response.data.channel;
          commit('setChannelItem', channel);
          return response.data;
        })
        .catch(error => {
          console.error('Error uploading cover image:', error);
          throw error;
        });
    },
  },

  getters: {
    getChannelItem(state) {
      return state.channelItem;
    },
    getChannels(state) {
      return state.channels;
    },
    getMaterialsCountForChannel: (state) => (channelId) => {
      const channel = state.channels.find(coll => coll.id === channelId);
      return channel ? channel.materials_count : 0;
    }
  },
};

export default channelModule;
