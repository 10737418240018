// store/modules/appModule.js

import axiosConfig from '@/lib/axiosConfig';
import { API_URL } from '@/config.js';

const state = {
  isMobile: false,
  configData: {},
  isPreview: false, // Добавленная переменная для режима превью
  darkMode: false, 
  chatbotName: ''
};

const mutations = {
  SET_IS_MOBILE(state, value) {
    state.isMobile = value;
  },
  SET_CONFIG_DATA(state, configData) {
    state.configData = configData;
  },
  SET_CHATBOT_NAME(state, value) {
    state.chatbotName = value;
  },
  SET_PREVIEW(state, { value }) { // Мутация для установки режима превью
    state.isPreview = value;
    if (value) {
      document.documentElement.classList.add('preview');
      document.documentElement.classList.add(value);
    } else {
      document.documentElement.classList.remove('preview');
    }
  },

  SET_DARK_MODE(state, value) { // Мутация для установки темной темы
    state.darkMode = value;
    console.log('DARK MODE ', value);

    const htmlElement = document.documentElement;
    if (value) {
      htmlElement.setAttribute('data-bs-theme', 'dark');
    } else {
      htmlElement.removeAttribute('data-bs-theme');
    }
  },

};

const actions = {
  setIsMobile({ commit }, value) {
    commit('SET_IS_MOBILE', value);
  },
  fetchConfigData({ commit }) {
    return axiosConfig
      .get(API_URL, {
        params: {
          action: 'get_config_data',
        },
      })
      .then(response => {
        const config = response.data.config_data;
        commit('SET_CONFIG_DATA', config);
      })
      .catch(error => {
        console.error(error);
        throw error;
      });
  },

  setPreview({ commit, dispatch }, value) {
    commit('SET_PREVIEW', { value });
    // Вызываем действие setDarkMode после установки режима превью
    dispatch('setDarkMode', value);
  },
  setDarkMode({ commit }, value) {
    commit('SET_DARK_MODE', value);
  },
  setChatbotName({ commit }, value) {
    console.log('chatbot_name ', value);
    commit('SET_CHATBOT_NAME', value);
  },
};

const getters = {
  isMobile: (state) => state.isMobile,
  configData: (state) => state.configData,
  isPreview: (state) => state.isPreview, // Геттер для доступа к режиму превью
  darkMode: (state) => state.darkMode, // Геттер для доступа к темной теме
  chatbotName: (state) => state.chatbotName

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
