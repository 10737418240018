<template>
  <div class="mt-3">
    <ArticleNextLessonComponent :articleItem = "articleItem" v-if="!articleItem.forbidden"/>
    <!-- Режим редактирования -->
<div v-if="editingMode" class="container">

  <h2><i class="bi bi-pencil-fill"></i> Редактирование теста {{ testTitle }}</h2>
  
  <div v-for="(group, groupIndex) in testGroups" :key="groupIndex" class="card my-4">
    <div class="card-header d-flex justify-content-between">
      <input v-model="group.title" class="form-control me-2">
      <button @click="removeGroup(groupIndex)" class="btn btn-danger"><i class="bi bi-trash"></i></button>
    </div>
    <div class="card-body">
      
      <div v-for="(question, questionIndex) in group.questions" :key="questionIndex" class="mb-3">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <h4>Вопрос {{ questionIndex + 1 }}</h4>

             <div class="d-flex">
                
                <button @click="useInQuiz(groupIndex, questionIndex)" class="btn btn-primary" v-if="!question.use_for_quiz && articleItem.group_alias == 'EduGrowth'" title="Использовать в квизах">
                  <i class="bi bi bi-question"></i>
                </button>

                <button @click="useInQuiz(groupIndex, questionIndex)" class="btn btn-success" v-if="question.use_for_quiz == true && articleItem.group_alias == 'EduGrowth'" title="Не использовать в квизах">
                  <i class="bi bi bi-question-circle"></i>
                </button>

                <button @click="removeQuestion(groupIndex, questionIndex)" class="btn btn-danger ms-2 "><i class="bi bi-trash"></i></button>
              </div>
          </div>
          <div class="card-body">
            <textarea v-model="question.question" class="form-control mb-3"></textarea>
            <div v-for="(answer, answerIndex) in question.answers" :key="answerIndex">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <input type="checkbox" v-model="answer.is_right">
                  </div>
                </div>
                <input type="text" v-model="answer.answer" class="form-control ms-2 me-2">
                <div class="input-group-append">
                  <button @click="removeAnswer(groupIndex, questionIndex, answerIndex)" class="btn btn-danger"><i class="bi bi-trash"></i></button>
                </div>
              </div>
            </div>
            <button @click="addAnswer(groupIndex, questionIndex)" class="btn btn-primary mb-3 me-2"><i class="bi bi-plus"></i> Добавить ответ</button>
            
          </div>
        </div>
      </div>
      <button @click="addQuestion(groupIndex)" class="btn btn-primary mb-3"><i class="bi bi-plus"></i> Добавить вопрос</button>
    </div>
  </div>
  <div class="d-block d-sm-flex justify-content-between">
    <button @click="addGroup" class="btn btn-primary w-100 w-sm-auto me-1 mb-1 mb-sm-0"><i class="bi bi-plus"></i> Добавить группу вопросов</button>
    <button @click="finishEditing" class="btn btn-success w-100 w-sm-auto me-1 mb-1 mb-sm-0"><i class="bi bi-check"></i> Завершить редактирование</button>
  </div>
  
</div>

    <!-- Режим просмотра -->
    <div v-else>
      <div  ref="testStart" class=" mb-2 mt-3">
        <h2>Тест к этому материалу</h2>

        <button v-if="hasEditAccess" @click="toggleEditingMode" class="btn btn-primary btn-block mb-2 mb-md-0 me-md-2">
        {{ editingMode ? 'Завершить редактирование' : 'Редактировать тест' }}
        </button>

        <button v-if="hasEditAccess" @click="copyTestLink()" class="btn btn-primary btn-block">
          Копировать ссылку на тест
        </button>
      </div>

      <div class="alert alert-info" v-if="0">
        <p>
          Друзья! Этот небольшой тест позволит вам применить полученные знания на практике. С помощью нейросетей были созданы разнообразные вопросы и задания, чтобы вы могли проверить свой прогресс и улучшить понимание материала. Иногда вопросы могут быть немного искажены - в таком случае, <a href="https://t.me/ayarmots">сообщите нам</a>, пожалуйста, об ошибке. 
        </p>
<!--         <p>
          Не волнуйтесь, если результаты не будут идеальными. Цель теста - помочь вам развиваться и расти. Учение - это постоянный процесс, и каждый шаг вперед приносит вам ценный опыт и новые знания.
        </p>
        <p>
          Примите вызов и пройдите этот тест. Поделитесь своими результатами с нами и другими участниками сообщества. Пусть этот тест станет источником вдохновения для вас в дальнейшем обучении! 
        </p> -->
      </div>

      <p class="text text-warning" v-if="!isAuthenticated">
          ⚠️ Требуется <a href="#auth" class="text text-warning">войти</a> через Telegram, чтобы пройти тест.
      </p>
      <div v-if="!isTestSubmitted">
        <!-- Отображение вопросов теста -->
        <div v-for="(group, index) in testGroups" :key="index">
          <h3>{{ group.title }}</h3>
          <div v-for="(question, qIndex) in group.questions" :key="qIndex">
            <p><strong>{{ question.question }}</strong></p>
            <ul class="list-group mb-4">
              <li v-for="(answer, aIndex) in question.answers" :key="aIndex" class="list-group-item">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    :id="'question-' + group.id + '-' + question.id + '-' + aIndex"
                    :name="'question-' + group.id + '-' + question.id"
                    :disabled="isTestSubmitted || !isAuthenticated"
                    v-model="userAnswers[group.id][question.id]"
                    :value="answer.id"
                  />
                  <label class="form-check-label" :for="'question-' + group.id + '-' + question.id + '-' + aIndex">
                    {{ answer.answer }}
                  </label>
                </div>
              </li>
            </ul>
          </div>

        </div>

      

        <!-- Кнопка отправки ответов -->
        <div class="row align-items-center">
          <div class="col col-auto ">
            <button @click="submitTest" :disabled="isTestSubmitted || !isAuthenticated" class="btn btn-primary">Отправить ответы</button>

          </div>
          <div class="col col-auto align-items-center text text-warning" v-if="!isAuthenticated">
            ⚠️ Требуется <a href="#auth" class="text text-warning">войти</a> через Telegram, чтобы пройти тест.
          </div>
        </div>

      </div>

      <!-- Отображение результатов теста -->

      <div v-else class="mt-4" ref="testResults">
        
        <div class="card">
          <div class="card-body">
            <h4 class="mb-3">Результаты теста</h4>
            <div class="card-text"><div class="d-flex align-items-center"><img v-if="testResultUser && testResultUser.photo_url" :src="testResultUser.photo_url" :alt="testResultUser.first_name" class="rounded-circle" style="width: 30px; height: 30px; margin-right: 10px;"> <strong>{{ testResultUser.username}}</strong></div></div>

            <div class="row mb-3 mt-1">
              <div class="col-sm-12 col-md-6 card-text">Ваш результат: <strong>{{ testResultScore }}%</strong></div>
              <div class="col-sm-12 col-md-6 card-text">Дата: <strong>{{ testResultDate }}</strong></div>
            </div>

            <div class="row align-items-center">
             <div class="col-sm-12 col-md-6 mb-1"> 
              <button @click="takeTestAgain" class="btn btn-primary w-100">
                Пройти тест еще раз
              </button>
              </div>
             <div class="col-sm-12 col-md-6 mb-1"> 

              <button @click="copyTestResultsLink()" class="btn btn-success w-100">
                <i class="bi bi-share mr-2"></i> Поделиться 
              </button>
              </div>

            </div>

            

          </div>
        </div>
      
        <!-- Display correct and wrong answers -->
        <div class="mt-4">
          <h4>Ответы</h4>
          <div v-for="(group, index) in testGroups" :key="index" class="mb-3">
            <h5>{{ group.title }}</h5>
            <div v-for="(question, qIndex) in group.questions" :key="qIndex" class="mb-3">
              <p><strong>{{ question.question }}</strong></p>
              <ul class="list-group">
               <li v-for="(answer, aIndex) in question.answers" :key="aIndex" class="list-group-item"
                :class="{
                  'text-success': isAnswerCorrectAndNotSelected(group.id, question.id, answer.id),
                  'bg-success text-white': isAnswerCorrectAndSelected(group.id, question.id, answer.id),
                  'bg-danger text-white': isUserAnswerIncorrect(group.id, question.id, answer.id),
                }"
              >

                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      :id="'question-' + group.id + '-' + question.id + '-' + aIndex"
                      :name="'question-' + group.id + '-' + question.id"
                      :disabled="isTestSubmitted"
                      v-model="userAnswers[group.id][question.id]"
                      :value="answer.id"
                    />
                    <label class="form-check-label" :for="'question-' + group.id + '-' + question.id + '-' + aIndex">
                      {{ answer.answer }}
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <RatingTable :results="ratingResults" @test-results-updated="handleTestResultsUpdated"/>

  </div>
</template>

<script>

import { API_URL } from '@/config.js'; // Импортируем глобальную переменную
import { mapState, useStore, mapGetters } from 'vuex';
import RatingTable from './RatingTable.vue';
import { useToast } from "vue-toastification";
import { computed } from 'vue';
import ArticleNextLessonComponent from '@/components/ArticleNextLessonComponent.vue';



//import { NotifyMe } from 'vue-notify-me';

export default {
  data() {
    return {
      editingMode: false,
      hasEditAccess: false,
      authorized: false,
      userAnswers: {},
      testTitle: '', // Заголовок теста
      testGroups: [], // Группы вопросов
      ratingResults: {},
      isTestSubmitted: false, // Флаг для определения, отправлены ли уже ответы

    };
  },
  components: {
    RatingTable,
    ArticleNextLessonComponent
  },

  computed: {
    ...mapGetters('app', ['isMobile', 'configData', 'isPreview']), 
    ...mapState(['hash']),
    ...mapGetters("article", ["getArticleItem"]),
    articleItem: {
      get() {
        return this.getArticleItem || {};
      },
    }
  },
  setup() {
    const store = useStore();
    const isAuthenticated = computed(() => store.getters['user/isAuthenticated']);
    const user = computed(() => store.getters['user/getUser']);

    return {
      isAuthenticated,
      user
    };
  }, 
  created() {
    // Запрос данных теста с помощью AJAX

    this.$axios.get(API_URL, {
      params: {
        action: 'get_test'
      },
    })
     .then((response) => {

      console.log(response);
      const testData = response.data;

      this.testGroups = testData.map((group) => ({
        id: group.id,
        title: group.title,
        questions: (group.questions || []).map((question) => ({
          id: question.id,
          question: question.question,
          use_for_quiz: question.use_for_quiz,
          answers: question.answers.map((answer) => ({
            id: answer.id,
            answer: answer.answer,
            is_right: answer.is_right,
          })),
        })),
      }));

      this.initTest();
      this.loadTestResults();
    })

    this.fetchUserAccess();
  },
  methods: {
    addGroup() {
      this.testGroups.push({
        title: '',
        questions: [],
      });
      //this.userAnswers.push([]);
    },
    addQuestion(groupIndex) {
      this.testGroups[groupIndex].questions.push({
        question: '',
        answers: [],
      });
    },

    useInQuiz(groupIndex, questionIndex) {
      if (typeof this.testGroups[groupIndex].questions[questionIndex].use_for_quiz == 'undefined') this.testGroups[groupIndex].questions[questionIndex].use_for_quiz = false
      this.testGroups[groupIndex].questions[questionIndex].use_for_quiz = !this.testGroups[groupIndex].questions[questionIndex].use_for_quiz;
      // Optional: Perform additional actions, like updating a server or emitting an event
    },

    addAnswer(groupIndex, questionIndex) {
      this.testGroups[groupIndex].questions[questionIndex].answers.push({
        answer: '',
        is_right: false,
      });
    },
    removeAnswer(groupIndex, questionIndex, answerIndex) {
      this.testGroups[groupIndex].questions[questionIndex].answers.splice(answerIndex, 1);
    },
    removeQuestion(groupIndex, questionIndex) {
      this.testGroups[groupIndex].questions.splice(questionIndex, 1);
    },
    removeGroup(groupIndex) {
      this.testGroups.splice(groupIndex, 1);
    },

    scrollToTestResults() {
      this.$refs.testResults.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    },
    scrollToTest() {
      this.$refs.testStart.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    },    
    finishEditing() {
      this.saveTest();
      // Завершение режима редактирования
      this.editingMode = false;
    },
      
getAnswer(groupId, questionId, answerId) {
  const group = this.testGroups.find(group => group.id === groupId);
  const question = group?.questions.find(question => question.id === questionId);
  const answer = question?.answers.find(answer => answer.id === answerId);
  const userAnswer = this.userAnswers[groupId]?.[questionId];
  return { answer, userAnswer };
},

isAnswerCorrectAndNotSelected(groupId, questionId, answerId) {
  const { answer, userAnswer } = this.getAnswer(groupId, questionId, answerId);
  return answer?.is_right && (!userAnswer || userAnswer !== answer.id);
},


isAnswerCorrectAndSelected(groupId, questionId, answerId) {
  const { answer, userAnswer } = this.getAnswer(groupId, questionId, answerId);
  return answer?.is_right && userAnswer === answer?.id;
},

isAnswerIncorrect(groupId, questionId, answerId) {
  const { userAnswer, answer } = this.getAnswer(groupId, questionId, answerId);
  return answer && !answer.is_right && userAnswer === answer?.id;
},

isUserAnswerIncorrect(groupId, questionId, answerId) {
  const userAnswerId = this.userAnswers[groupId]?.[questionId];
  const { answer } = this.getAnswer(groupId, questionId, userAnswerId);
  return answer && !answer.is_right && answerId === userAnswerId;
},


  loadTestResults() {
    const urlParams = new URLSearchParams(window.location.search);
    const resultsHash = urlParams.get('results_hash');

    // Отправка запроса на сервер для получения результатов теста по хешу
    this.$axios
      .get(API_URL, {
        params: {
          action: 'get_test_results',
          resultsHash: resultsHash,
        },
      })
      .then((response) => {
        const testResults = response.data;
        if (testResults.resultsHash) this.handleTestResultsUpdated(testResults);
      })
      .catch((error) => {
        console.error('Error loading test results:', error);
      });
  },


    // Расчет процента правильных ответов
    calculateScore() {
  let totalQuestions = 0;
  let correctAnswers = 0;

  // Iterate over the testGroups
  for (const group of this.testGroups) {
    // Iterate over the questions in each group
    for (const question of group.questions) {
      totalQuestions++; // Increment the total questions counter

      const userAnswer = this.userAnswers[group.id][question.id];
      if (userAnswer !== undefined) {
        // Check if the user has selected an answer for the question
        const selectedAnswer = question.answers.find((answer) => answer.id === userAnswer);
        if (selectedAnswer && selectedAnswer.is_right) {
          correctAnswers++; // Increment the correct answers counter
        }
      }
    }
  }

  // Calculate the score as a percentage
  const score = Math.round((correctAnswers / totalQuestions) * 100);

  return score;
},

  handleTestResultsUpdated(testResults) {
    console.log(testResults);
    // Update the testGroups with the received test results
    this.testGroups = testResults.userAnswers;
    this.testResultScore = testResults.score;
    this.testResultDate = testResults.date;
    this.testResultHash = testResults.resultsHash;
    this.testResultUser = testResults.user;
    console.log('handleTestResultsUpdated');
    // Switch to the results display mode
    this.isTestSubmitted = true;
    this.initTest(true);
    this.$nextTick(() => {
      this.scrollToTestResults();
    });
  },

    copyTestLink() {
      // const currentURL = new URL(window.location.href);
      // currentURL.searchParams.set('results_hash', this.testResultHash);
      
      //let url = this.configData.web_app_link + 'article/'+this.articleItem.hash+'#test'
      let url = this.articleItem.telegram_test_link;
      navigator.clipboard.writeText(url)
        .then(() => {
          const toast = useToast();

          // or with options
          toast.success("Ссылка на тест скопирована в буфер обмена.", {
            timeout: 2000
          })
        })
        .catch((error) => {
          console.error('Ошибка при копировании ссылки:', error);
        });
    },  
    copyTestResultsLink() {
      // const currentURL = new URL(window.location.href);
      // currentURL.searchParams.set('results_hash', this.testResultHash);
      let url = this.configData.web_app_link + 'article/'+this.articleItem.hash+'?results_hash='+this.testResultHash+'#test'
      navigator.clipboard.writeText(url)
        .then(() => {
          const toast = useToast();

          // or with options
          toast.success("Ссылка на результаты скопирована в буфер обмена.", {
            timeout: 2000
          })
        })
        .catch((error) => {
          console.error('Ошибка при копировании ссылки:', error);
        });
    },  

    // Получение текущей даты
    getCurrentDate() {
      const date = new Date();
      return date.toLocaleDateString();
    },
    
    // Отправка ответов теста на сервер
    // Метод для сохранения выбранных ответов пользователя

    saveUserAnswers() {
      // Перебираем группы вопросов
      for (const group of this.testGroups) {
        // Перебираем вопросы в каждой группе
        for (const question of group.questions) {
          // Получаем выбранный пользователем ответ
          const selectedAnswer = this.userAnswers[group.id][question.id];

          // Перебираем ответы в каждом вопросе
          for (const answer of question.answers) {
            // Проверяем, является ли данный ответ выбранным пользователем
            answer.user_answer = answer.id === selectedAnswer;
          }
        }
      }
    },

    fetchUserAccess() {
      // Запрос данных о доступе пользователя с помощью AJAX
      this.$axios
        .get(API_URL, {
          params: {
            action: 'get_user_access',

            // Параметры запроса, если необходимо
          },
        })
        .then((response) => {
          // Обработка полученных данных о доступе пользователя
          //const userAccess = response.data.hasAccess;
          this.hasEditAccess = response.data.hasAccess;
        })
        .catch((error) => {
          console.error('Error fetching user access:', error);
        });
    },
    toggleEditingMode() {
      this.editingMode = !this.editingMode;
    },

    // Метод для отправки ответов на сервер
    submitTest() {
      // Сохраняем выбранные ответы пользователя
      this.saveUserAnswers();

      // Генерация уникального ID пользователя
      
      this.$axios.post(API_URL, {
        params: {
          action: 'submit_test_result',
          testGroups: this.testGroups,
          // Другие параметры запроса
        }
      })
        .then(response => {
          console.log(response);
          this.handleTestResultsUpdated(response.data);
          this.isTestSubmitted = true;
          this.$nextTick(() => {
            // Scroll to test results
            this.scrollToTestResults();
          });
          
          // Handle successful save response
        })
        .catch(error => {
          console.log(error);
          // Handle save error
        });
    },

    takeTestAgain() {
      // Reset necessary data properties
      this.isTestSubmitted = false;
      // Reset other data properties as needed

      // Scroll back to the top of the page
      this.scrollToTest();
      this.initTest();
    },    

    initTest(show_test_results) {

      if (show_test_results){
        this.userAnswers = {};
        this.testGroups.forEach((group) => {
          this.userAnswers[group.id] = {};
          group.questions.forEach((question) => {
            this.userAnswers[group.id][question.id] = '';

            // Prefill userAnswers if the question has a selected answer
            const selectedAnswer = question.answers.find((answer) => answer.user_answer);
            if (selectedAnswer) {
              this.userAnswers[group.id][question.id] = selectedAnswer.id;
            }

          });
          //console.log(this.userAnswers);
        });

        //this.shuffleAnswers();
      } else {
        this.userAnswers = {};
        this.testGroups.forEach((group) => {
          this.userAnswers[group.id] = {};
          group.questions.forEach((question) => {
            this.userAnswers[group.id][question.id] = [];
          });
        });
        this.shuffleAnswers();
      }
    },

    shuffleAnswers() {
        for (const group of this.testGroups) {
          for (const question of group.questions) {
            // Shuffle the answers using the Fisher-Yates algorithm
            for (let i = question.answers.length - 1; i > 0; i--) {
              const j = Math.floor(Math.random() * (i + 1));
              [question.answers[i], question.answers[j]] = [question.answers[j], question.answers[i]];
            }
          }
        }
      },

    saveTest() {
      this.$axios.post(API_URL, { params: {
        action: 'save_test',
        testGroups: this.testGroups // Передача измененных данных теста
      }})
      .then((response) => {
        console.log(response);
          const toast = useToast();

          // or with options
          toast.success("Тест успешно сохранен", {
            timeout: 2000
          });
      })
      .catch((error) => {
        console.log(error)
        // Обработка ошибок при сохранении
      });
    },    
  },
  mounted(){
    // Проверка наличия параметра results_hash в адресной строке
    const urlParams = new URLSearchParams(window.location.search);
    const resultsHash = urlParams.get('results_hash');

    if (resultsHash) {
      // Если параметр results_hash присутствует, загружаем результаты теста и включаем режим просмотра
      this.loadTestResults();
    }
  }
};
</script>
