<template>
  <div class="container mt-4">
    <div v-if="channel">

      <div v-if="!isPreview">
        <div class="row">

          <div class="col-4">
            <img :src="channel.thumbs.big" class="mb-3 cover" v-if="channel.thumbs" />
          </div>
          <div class="col-8 d-flex align-items-center">
            <div>
              <div class="text-muted small" v-if="0">Автор: <a :href="'https://t.me/' + channel.username">{{channel.username}}</a></div>
              <h1>{{ channel.caption }}</h1>
              <div class="" v-html="channel.description"></div>
            </div>
          </div>

        </div>

        <div id="collections-list" class="mb-4" v-if="channel.collections.length">
          <h2>Плейлисты в канале</h2>
          <div class="row d-flex">
            <div class="col-md-4" v-for="collection in channel.collections" :key="collection.id">
              <div class="card mb-3 h-100">
                <div class="card-body d-flex flex-column">
                  <div class="d-flex align-items-start mb-2">
                    <div class="mr-2" v-if="collection.thumbs.big">
                      <img :src="collection.thumbs.big" alt="" class="thumb">
                    </div>
                    <div class="thumb empty-thumb" v-if="!collection.thumbs.big">
                      <i class="bi bi-camera"></i>
                    </div>
                  </div>
                  <div>
                    <h4 class="card-title mb-1">
                      <router-link :to="{ name: 'Collection', params: { id: collection.hash } }">
                        {{ collection.caption }}
                      </router-link>
                    </h4>
                    <p v-if="collection.materials_count" class="card-text mb-0">{{ collection.materials_count }} материалов</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

       <div id="collections-list" class="mb-4" v-if="channel.courses.length ">
          <h2>Курсы в канале</h2>
          <div class="row d-flex">
            <div class="col-md-4" v-for="collection in channel.courses" :key="collection.id">
              <div class="card mb-3 h-100">
                <div class="card-body d-flex flex-column">
                  <div class="d-flex align-items-start mb-2">
                    <div class="mr-2" v-if="collection.thumbs.big">
                      <img :src="collection.thumbs.big" alt="" class="thumb">
                    </div>
                    <div class="thumb empty-thumb" v-if="!collection.thumbs.big">
                      <i class="bi bi-camera"></i>
                    </div>
                  </div>
                  <div>
                    <h4 class="card-title mb-1">
                      <router-link :to="{ name: 'Collection', params: { id: collection.hash } }">
                        {{ collection.caption }}
                      </router-link>
                    </h4>
                    <p v-if="collection.materials_count" class="card-text mb-0">{{ collection.materials_count }} материалов</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="mb-3"  v-if="!isPreview">
        <h2>Материалы</h2>
        <ul class="nav nav-tabs">

          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: materialType === 'newest' }"
              @click="selectMaterialType('newest')"
              href="javascript:void(0);"
            >Новые</a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: materialType === 'oldest' }"
              @click="selectMaterialType('oldest')"
              href="javascript:void(0);"
            >Старые</a>
          </li>
        </ul>
      </div>


<ul class="nav nav-pills justify-content-center mb-3">
   <li class="nav-item">
      <button
        class="btn btn-outline-primary me-2 mb-2"
        :class="{ active: selectedCategoryId === -1 }"
        @click="selectCategory( {id: -1} )"
      >
        Показать все
      </button>
    </li>
     
    <li v-for="category in channel.categories" :key="category.id" class="nav-item">
      <button
        class="btn btn-outline-primary me-2 mb-2"
        :class="{ active: selectedCategoryId === category.id }"
        @click="selectCategory(category)"

      >
        {{ category.caption }}
      </button>
    </li>

    <li class="nav-item">
      <router-link to="/materials" v-if="hasEditAccess">
        <button class="btn btn btn-outline-primary">Добавить материал</button>
      </router-link>
    </li>

  </ul>

      <div class="text-center" v-if="materialsLoading">
        <spinner-component :visible="materialsLoading"></spinner-component>
      </div>

      <div class="row row-cols-1 row-cols-md-3 g-4 materials-cards">
        

        <div v-for="material in channel.materials" :key="material.id" class="col">
          <div class="card h-100">
            <div class="card-body">
              <div class="text-center mb-2" v-if="material.smallpicture">
                <img
                  v-if="material.smallpicture"
                  :src="material.smallpicture"
                  alt=""
                  class="img-thumbnail img-cover"
                />
              </div>

              <div class="bottom-part" v-if="isPreview">
                <h5 class="card-title" v-if="isPreview">
                  {{ material.caption }}
                </h5>
                <button class="btn btn-primary btn-sm w-100 w-sm-auto mt-2" v-if="isPreview" @click="selectMaterialForBot(material.hash)">Открыть</button>

              </div>
              <div class="bottom-part" v-if="!isPreview">
                <h5 class="card-title">
                  <router-link :to="{ name: 'Article', params: { id: material.hash } }">{{ material.caption }}</router-link>
                </h5>
                <div v-if="material.open_status === 0">
                  <span class="badge bg-danger">🔒 Закрыто</span>&nbsp;
                  <span v-if="material.open_by_date" class="text-muted small">Доступ будет открыт {{ material.open_by_date }}</span>
                  <span v-if="material.open_by_test_score" class="text-muted small">Нужна оценка за предыдущий тест: {{ material.open_by_test_score }} баллов</span>
                </div>
                <div v-else-if="material.open_status === 1">
                  <span class="badge bg-success">Открыто</span>&nbsp;
                </div>
                <div v-else-if="material.open_status === 2">
                  <span class="badge bg-success">Открыто</span>&nbsp;
                  <span v-if="material.open_by_test_score" class="text-muted small">Оценка за предыдущий тест: {{ material.open_status_test_score }}</span>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div v-else>
      <spinner-component :visible="true"></spinner-component>
    </div>
  </div>
</template>


<script>
import { ref, onMounted, computed} from 'vue';
import { useRoute } from 'vue-router';
import { useStore, mapGetters} from 'vuex';
import SpinnerComponent from '@/components/SpinnerComponent.vue';

export default {
 data() {
    return {
      materialsLoading: false,
    };
  },
  computed: {
    ...mapGetters('app', ['isMobile', 'configData', 'isPreview']),
  },
  components: {
      SpinnerComponent
  },
  methods: {
    selectMaterialType(type) {
      console.log(type);
      this.materialType = type;

      this.materialsLoading = true;
      this.channel.materials = [];
      var payload = {
            channelId: this.channel.id,
            materialsType: type,
          };

        if (this.selectedCategoryId > -1){
          payload.categoryId = this.selectedCategoryId
        }

        console.log(payload);

        this.$store.dispatch('channel/fetchChannelMaterials', payload)
        .then((data) => {
          console.log(data);
          this.channel.materials = data.channel_materials
          this.materialsLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.materialsLoading = false;

          // Обработка ошибок при загрузке информации о подборке
        });
    },

    fetchCollections(){
      var payload = {
                channelId: this.channel.id
              };


      this.$store.dispatch('collection/fetchCollections', payload)
        .then((data) => {
          console.log(data);
          this.channel.collections = data.collections
        })
        .catch((error) => {
          console.log(error);
          // Обработка ошибок при загрузке информации о подборке
        });
    }, 

    selectCategory(category){
      this.selectedCategoryId = category.id

      this.selectMaterialType(this.materialType);
    },

    selectMaterialForBot(hash) {
      this.$store.dispatch('tgconnector/sendCommandToBot', {
        params: { material_hash: hash, command: 'show_channel_material'}
      });
    },

  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const channelId = ref(null);
    const channel = ref(null);
    const materialType = ref('newest');
    const selectedCategoryId = ref(-1); // Определение selectedCategoryId

    const hasEditAccess = computed(() => store.getters['user/hasEditAccess']);
  
    // Получение параметра "id" из URL
    onMounted(() => {
      channelId.value = route.params.id;
      console.log(channelId.value);

      // Получение значения categoryId из адресной строки
      const categoryId = route.query.categoryId;
      if (categoryId !== undefined) {
        selectedCategoryId.value = parseInt(categoryId); // Преобразуем значение в число и устанавливаем selectedCategoryId
      }

      // Загрузка информации о канале
      store.dispatch('channel/fetchChannel', { channelId: channelId.value })
        .then((data) => {
          console.log(data);
          channel.value = data.channel;
          document.title = data.channel.caption;

          if (categoryId !== undefined) {
            const category = data.channel.categories.find(cat => cat.id === selectedCategoryId.value);
            if (category) {
              store.commit('setSelectedCategoryId', selectedCategoryId.value); // Обновляем selectedCategoryId в store, если используется Vuex
              store.dispatch('channel/fetchChannelMaterials', {
                channelId: data.channel.id,
                materialsType: materialType.value,
                categoryId: selectedCategoryId.value,
              }).then((materialsData) => {
                channel.value.materials = materialsData.channel_materials;
              }).catch((error) => {
                console.log(error);
              });
            } 
          }         
        })
        .catch((error) => {
          console.log(error);
        });
    });

    return {
      channelId,
      channel,
      materialType,
      selectedCategoryId, // Возвращаем selectedCategoryId
      hasEditAccess
    };
  },
};
</script>

<style>
/* Дополнительные стили Bootstrap можно добавить здесь */
.cover{
  width: 100%;
  height: auto;
  border-radius: 10px;

}

#collections-list{
  padding: 1rem;
  background: #f4eeff;
  border-radius: 6px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

#collections-list .thumb{
  width: 100%;
  height: auto;
}

#collections-list .thumb.empty-thumb{
  width: 100%;
  height: 120px;
}

.img-thumbnail{
  border: 0;
}

.cover-bg{
  display: flex;
  color:#FFF;
  justify-content: space-around;
  align-items: center;
  height: 250px;  
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.materials-cards .card-body{
  padding:0;
}

.materials-cards .card-body .img-cover{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.materials-cards .card-body .img-thumbnail{
  padding: 0;
}

.bottom-part{
  padding: 0 1rem 1rem 1rem;
}
</style>
